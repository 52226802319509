.popedFloorPlan {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.defaultFloorPlan {
  position: absolute;
  top: 68px;
  right: 0;
  width: 15vw;
  height: 15vw;
  min-width: 100px;
  min-height: 100px;
  border-radius: 1em 0.5em;
}
