.viweBtn-container {
  display: flex;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  width: 30%;
  height: 3rem;
  flex-direction: row;
  border-radius: 100px;
  background: linear-gradient(rgba(0, 0, 0, 0.66), rgba(0, 0, 0, 0.3));

  @media only screen and (max-width: 1024px) {
    display: none;
  }
}

.viewButton {
  width: 33.3%;
  height: auto;
  display: flex;
  padding: 0;
  border: 0;
  background-color: rgba(255, 255, 255, 0);
  z-index: 100;
  cursor: pointer;
  outline: 0;
  justify-content: center;
  align-items: center;
  color: white;
  flex-grow: 1;
  overflow-x: hidden;
  white-space: nowrap;
  box-sizing: content-box;
}

.buttonMask {
  position: absolute;
  width: 33%;
  height: 92%;
  top: 4%;
  left: 0.5%;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s;
  z-index: -1;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 100px;
}

.buttonText {
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-family: Microsoft JhengHei;
}

@media only screen and (max-width: 1024px) {
  .buttonText {
    display: none;
  }
}
