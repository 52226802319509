@import 'asset/css/colors';

.roomTag {
  position: absolute;
  transform: translate(50%, 50%);
  transition: transform 0.5s;
  background: #00000087;
  color: white;
  border: 3px solid;
  border-color: white;
  padding: 2px 3px;
  white-space: nowrap;
  border-radius: 5px;
  cursor: pointer;
}
