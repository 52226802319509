@import 'asset/css/colors';

.swiper-list {
  height: 11rem;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.5s;

  @media only screen and (orientation: landscape) and (max-width: 1024px) {
    height: 2rem;
  }
}

.button-container {
  height: 2rem;
  width: 2rem;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  margin-bottom: 0;
  flex-shrink: 0;

  @media only screen and (orientation: landscape) and (max-width: 1024px) {
    display: none;
  }
}
.down {
  border: solid #d8d8d8;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.panorama-swiper {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;

  height: 7rem;
  background: rgba(0, 0, 0, 0.3);
  margin-top: auto;
  flex-shrink: 0;

  @media only screen and (orientation: landscape) and (max-width: 1024px) {
    display: none;
  }
}

.swiper-button:hover ~ .panorama-swiper {
  transform: translateY(100%);
}
.swiper-button {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;

  img {
    width: 4rem;
    height: 100%;
  }
}

div.swiper-slide {
  width: 12rem;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  img {
    top: 0;
    bottom: 0;
    background: none;
    padding-top: 1%;
    padding-left: 1%;
    padding-right: 1%;
    padding-bottom: 1%;
    width: 100%;
  }
  img:hover {
    background: rgba(0, 255, 255, 1);
  }
}
.room-type {
  position: absolute;
  top: 70%;
  left: 0;
  right: 0;
  text-align: center;
  color: white;
}
.panoramas-list-item-container {
  border: none;
  padding-top: 5%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5%;
  position: relative;
  overflow: hidden;
  background: none;
  display: flex;
  cursor: pointer;
}

.go-button-container {
  height: 2rem;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 2rem 2rem 0px 0px;
  margin-bottom: auto;
  @media only screen and (min-width: 1025px) {
    display: none;
  }

  @media only screen and (orientation: landscape) and (max-width: 1024px) {
    width: 50vw;
  }
}

.go-button {
  height: 100%;
  flex-grow: 1;
  display: flex;
  padding: 0;
  border: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  flex-grow: 1;
  white-space: nowrap;
  box-sizing: content-box;
}

.hidden {
  display: none;
}
